
import {
    Grid, IconButton, 
    Paper, Table, 
    TableBody, TableCell, 
    TableContainer, TableHead, 
    TableRow, Typography 
} from '@mui/material'
import React from 'react'
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import { NumberFormating } from '../formatting';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import FolderZipIcon from '@mui/icons-material/FolderZip';

export default function Submitted({data, dataSet}) {


const downloadFile = (file1, file2) => {
    const zip = new JSZip();
    const pdfBlob1 = new Blob([file1], { type: 'application/pdf' });
    const pdfBlob2 = new Blob([file2], { type: 'application/pdf' });
    zip.file("quotation.pdf", pdfBlob1);
    zip.file("specification.pdf", pdfBlob2);
    zip.generateAsync({ type: "blob" })
        .then((content) => {
        saveAs(content, "documents.zip");
        });
}
    
    
  return (
    <Grid container lg={12} sm={12} sx={{display:'flex', flexDirection:'row'}}>
    <Grid item lg={12} sm={12} sx={{padding:5}}>
        <Typography sx={{fontSize:18, fontWeight:600}}>Submitted Quotations</Typography>
        <Grid item lg={8} sm={8} sx={{mt:2}}>
         <TableContainer component={Paper} sx={{boxShadow:5}}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                <TableRow>
                    <TableCell sx={{fontSize:'0.70rem'}} align="center">Quo/Spec</TableCell>
                    <TableCell sx={{fontSize:'0.75rem'}} align="right">Currency Type</TableCell>
                    <TableCell sx={{fontSize:'0.75rem'}} align="right">Unit Price</TableCell>
                    <TableCell sx={{fontSize:'0.75rem'}} align="right">Unit Price (+Tax)</TableCell>
                    <TableCell sx={{fontSize:'0.75rem'}} align="right">Total Price (+Tax)</TableCell>
                    <TableCell sx={{fontSize:'0.80rem'}} align="center">Lead Time</TableCell>
                    <TableCell sx={{fontSize:'0.75rem'}}>Remark</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                        {
                            data.length > 0 && data.map((value, index) => {
                                return(
                                    <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell  align="center">
                                        <IconButton target="_blank" onClick={() => downloadFile(value.quotation_file, value.quotation_spec_file)}  size='small' title='Download Quotation/Specification'>
                                            <FolderZipIcon sx={{fontSize:'1.3rem'}}/>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell  sx={{fontSize:'0.75rem'}} align="right">{dataSet?.currenceis?.filter((v) => v.id == value.currency_id)?.[0]?.code}</TableCell>
                                    <TableCell  sx={{fontSize:'0.75rem'}} align="right">{NumberFormating(value.exclusive_price, 2)}</TableCell>
                                    <TableCell  sx={{fontSize:'0.75rem'}} align="right">{NumberFormating(value.price, 2)}</TableCell>
                                    <TableCell  sx={{fontSize:'0.75rem'}} align="right">{NumberFormating(value.total,2)}</TableCell>
                                    <TableCell  sx={{fontSize:'0.75rem'}} align="right">{value.lead_time}</TableCell>
                                    <TableCell  sx={{fontSize:'0.75rem'}}>{value.remark}</TableCell>
                                </TableRow>
                                )
                            })
                        }
                </TableBody>
            </Table>
            </TableContainer>
        </Grid>
    </Grid>
  </Grid>
  )
}
