import { faCheckCircle, faPlusCircle } from '@fortawesome/fontawesome-free-solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Autocomplete, Box, Button, Divider, Grid, Grow, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import { NumberFormatInput } from '../formatting'
import ItemTable from '../table'
import parse from 'html-react-parser';
import ShowPackageItems from '../Models/showPackgaeItems'

export default function Create({formik, handleDeleteRaw, dataSet, toSubmit, hanldeSubmit}) {
  const [viewList, setViewList] = useState(false);
  const [listData, setListData] = useState([]);

  const viewSubList = () => {
    setViewList(true);
  }

  useEffect(() => {
    if(dataSet != null && dataSet?.asset_item?.asset_item_bulk?.length > 0){
      setListData(dataSet?.asset_item?.asset_item_bulk);
    }else{
      setListData([]);
    }
  }, [dataSet])

  const closeList = () => {
    setViewList(false);
  }

  return (
    <Grid container lg={12} sm={12} sx={{display:'flex', flexDirection:'row'}}>
    <Grid item lg={4} sm={12} sx={{padding:5}}>
        <Typography sx={{fontSize:18, fontWeight:600}}>Enter Quotations</Typography>

      <Grid sx={{mt:3}}>
          <TextField
              fullWidth
              type="file"
              id="quotation"
              label="Quotation"
              size='small'
              InputLabelProps={{ shrink: true }}
              inputProps={{accept:"application/pdf"}}
              onChange={(e) => formik.setFieldValue('quotation', e.target.files[0])}
              helperText={formik.touched.quotation ||  Boolean(formik.errors.quotation)? formik.errors.quotation : ""} 
              error={Boolean(formik.errors.quotation)} 
          />
      </Grid>
      <Typography sx={{fontSize:10, fontWeight:600, padding:0, margin:0, height:5, color:'#a59f98'}}>* Maximum Upload File Size is 2MB</Typography>
      <Typography sx={{fontSize:10, fontWeight:600, padding:0, margin:0, height:5, marginTop:1, color:'#466fe6'}}>* Model information & detailed pricing would be sufficient.</Typography>
      <Grid sx={{mt:3}}>
          <TextField
              fullWidth
              type="file"
              id="specification"
              label="Specification"
              size='small'
              InputLabelProps={{ shrink: true }}
              inputProps={{accept:"application/pdf"}}
              onChange={(e) => formik.setFieldValue('specification', e.target.files[0])}
              helperText={formik.touched.specification ||  Boolean(formik.errors.specification)? formik.errors.specification : ""} 
              error={Boolean(formik.errors.specification)} 
          />
      </Grid>
      <Typography sx={{fontSize:10, fontWeight:600, padding:0, margin:0, height:5, color:'#a59f98'}}>* Maximum Upload File Size is 2MB</Typography>
      <Typography sx={{fontSize:10, fontWeight:600, padding:0, margin:0, height:5, marginTop:1, color:'#466fe6', lineHeight: "12px"}}>* Technical evaluation team can only see this document. Make sure you elaborate your solution thoroughly.</Typography>
      <Grid sx={{mt:5}}>
            <Autocomplete
            size='small'
            id="currency-select"
            options={dataSet?.active_currencies}
            autoHighlight
            getOptionLabel={(option) => option.code}
            onChange={(e, value) => {formik.setFieldValue("currency_type", value); console.log('value', value)}}
            value={formik.values.currency_type}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
               <span style={{marginRight:5}}>{option.code}</span><span style={{fontSize:10}}> ({option.name})</span>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size='small'
                label="Select Currency Type"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
                helperText={formik.touched.currency_type ||  Boolean(formik.errors.currency_type)? formik.errors.currency_type : ""} 
                error={Boolean(formik.errors.currency_type)} 
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
      </Grid>
      <Grid sx={{mt:3}}>
          <TextField
              fullWidth
              type="text"
              id="exclusive_price"
              label="Unit Price"
              size='small'
              InputLabelProps={{ shrink: true }}
              onChange={(e) => formik.setFieldValue("exclusive_price", e.target.value)}
              value={formik.values.exclusive_price}
              helperText={formik.touched.exclusive_price ||  Boolean(formik.errors.exclusive_price)? formik.errors.exclusive_price : ""} 
              error={Boolean(formik.errors.exclusive_price)} 
              InputProps={{ inputComponent: NumberFormatInput }}
              inputProps={{maxLength:20}}
          />
      </Grid>
      <Grid sx={{mt:3}}>
          <TextField
              fullWidth
              type="text"
              id="price"
              label="Unit Price (+Tax)"
              size='small'
              InputLabelProps={{ shrink: true }}
              onChange={(e) => formik.setFieldValue("price", e.target.value)}
              value={formik.values.price}
              helperText={formik.touched.price ||  Boolean(formik.errors.price)? formik.errors.price : ""} 
              error={Boolean(formik.errors.price)} 
              InputProps={{ inputComponent: NumberFormatInput }}
              inputProps={{maxLength:20}}
          />
      </Grid>
      <Grid sx={{mt:3}}>
          <TextField
              fullWidth
              type="text"
              id="total_price"
              label="Total Price (+Tax)"
              size='small'
              InputLabelProps={{ shrink: true }}
              onChange={(e) => formik.setFieldValue("total_price", e.target.value)}
              value={formik.values.total_price}
              helperText={formik.touched.total_price ||  Boolean(formik.errors.total_price)? formik.errors.total_price : ""} 
              error={Boolean(formik.errors.total_price)} 
              InputProps={{ inputComponent: NumberFormatInput }}
              inputProps={{maxLength:20}}
          />
      </Grid>
      <Grid sx={{mt:3}}>
          <TextField
              fullWidth
              type="number"
              id="lead_time"
              label="Lead Time (In Days)"
              size='small'
              InputLabelProps={{ shrink: true }}
              onChange={(e) => formik.setFieldValue("lead_time", e.target.value)}
              value={formik.values.lead_time}
              helperText={formik.touched.lead_time ||  Boolean(formik.errors.lead_time)? formik.errors.lead_time : ""} 
              error={Boolean(formik.errors.lead_time)} 
              InputProps={{ inputComponent: NumberFormatInput }}
              inputProps={{maxLength:20}}
          />
      </Grid>
      <Typography sx={{fontSize:10, fontWeight:600, padding:0, margin:0, height:5, color:'#a59f98', lineHeight: "12px"}}>Lead time is the time it takes from receiving an order to delivering it to the customer</Typography>
      <Grid sx={{mt:4}}>
          <TextField
              fullWidth
              type="text"
              id="remark"
              label="Remark"
              size='small'
              multiline
              rows={3}         
              inputProps={{ maxLength: 200 }}
              InputLabelProps={{ shrink: true }}
              onChange={formik.handleChange}
              value={formik.values.remark}
              helperText={formik.touched.remark ||  Boolean(formik.errors.remark)? formik.errors.remark : ""} 
              error={Boolean(formik.errors.remark)} 
          />
      </Grid>
      <Grid sx={{mt:3}} display="flex" flexDirection="row" justifyContent="end">
        <Button onClick={formik.handleSubmit} size='small' variant='contained' color='info' sx={{color:'#fff'}} startIcon={<FontAwesomeIcon icon={faPlusCircle}/>}>Add Quotation</Button>
      </Grid>
    </Grid>
    <Grid container lg={1} sm={1} display="flex" flexDirection="row" justifyContent="center">
      <Divider orientation="vertical" flexItem />
    </Grid>
    <Grid lg={7} sm={12}  sx={{paddingY:5, paddingRight:5}}>
      <Grid lg={12} sm={12} display="flex" flexDirection="row">
        <Grid lg={6} sm={12} display="flex" flexDirection="column">
            <Grid lg={12} sm={12} display="flex" flexDirection="row" maxHeight={40}>
                <Grid lg={4} sm={12} display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography sx={{fontSize:13, fontWeight:600}}>Requested Item</Typography>
                  <Typography sx={{fontSize:13, fontWeight:600}}>:</Typography>
                </Grid>
                <Grid sx={{pl:2}} lg={8} sm={12}>
                  <Typography sx={{fontSize:13}}>{dataSet?.asset_item?.public_name}</Typography>
                </Grid>
            </Grid>
            <Grid lg={12} sm={12} display="flex" flexDirection="row" maxHeight={40}>
                <Grid lg={4} sm={12} display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography sx={{fontSize:13, fontWeight:600}}>Item Type</Typography>
                  <Typography sx={{fontSize:13, fontWeight:600}}>:</Typography>
                </Grid>
                <Grid sx={{pl:2}} lg={8} sm={12}>
                  <Typography sx={{fontSize:13}}>{dataSet?.asset_item?.asset?.name}</Typography>
                </Grid>
            </Grid>
            <Grid lg={12} sm={12} display="flex" flexDirection="row" maxHeight={40}>
                <Grid lg={4} sm={12} display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography sx={{fontSize:13, fontWeight:600}}>Quantity</Typography>
                  <Typography sx={{fontSize:13, fontWeight:600}}>:</Typography>
                </Grid>
                <Grid sx={{pl:2}} lg={8} sm={12}>
                  <Typography sx={{fontSize:13}}>{dataSet?.quotation_request?.requisition?.requisition_item?.quantity} pcs</Typography>
                </Grid>
            </Grid>
        </Grid>
        <Grid lg={6} sm={12} display="flex" flexDirection="column">
            <Grid lg={12} sm={12} display="flex" flexDirection="row" maxHeight={40}>
                <Grid lg={4} sm={12} display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography sx={{fontSize:13, fontWeight:600}}>Requested Date</Typography>
                  <Typography sx={{fontSize:13, fontWeight:600}}>:</Typography>
                </Grid>
                <Grid sx={{pl:2}} lg={8} sm={12}>
                  <Typography sx={{fontSize:13}}>
                    <Moment format='lll'>{dataSet?.quotation_request?.created_at}</Moment>  
                  </Typography>
                </Grid>
            </Grid>
            {
              dataSet?.quotation_request?.requisition?.requisition_item?.item_description != undefined && 
              dataSet?.quotation_request?.requisition?.requisition_item?.item_description != null?
              <Grid lg={12} sm={12} display="flex" flexDirection="column" >
                  <Grid lg={4} sm={12} display="flex" flexDirection="row" justifyContent="space-between">
                    <Typography sx={{fontSize:13, fontWeight:600}}>Item Description</Typography>
                    <Typography sx={{fontSize:13, fontWeight:600}}>:</Typography>
                  </Grid>
                  <Grid lg={12} sm={12}>
                    <Typography sx={{fontSize:13, background:'#feffb3'}}>
                      {parse(dataSet?.quotation_request?.requisition?.requisition_item?.item_description)}
                    </Typography>
                  </Grid>
              </Grid>
              :null
            }
        </Grid>
      </Grid>
      {
        dataSet?.asset_item?.asset_item_bulk != null && dataSet?.asset_item?.asset_item_bulk?.length > 0 ?
          <Grid lg={12} sm={12} display="flex" flexDirection="column">
              <Typography style={{fontSize:12, fontWeight:800, marginTop:5, color:'#d2691e'}}>This package consists of {dataSet?.asset_item?.asset_item_bulk?.length} items, all of which are mandatory. Please click the button below to view the list of required items.</Typography>
              <Button variant='contained' style={{width:180, fontSize:11, background:'#897777'}} onClick={viewSubList} size='small' startIcon={<FontAwesomeIcon style={{fontSize:14}} icon={faCheckCircle}/>}>
                View Required Items
              </Button>
          </Grid>
        :null
      }
      <Grow in={toSubmit?.length > 0}>
        <Grid lg={12} sm={12} sx={{mt:5}}>
          <ItemTable data={toSubmit} handleDeleteRaw={(index) => handleDeleteRaw(index)}/>
        </Grid>
      </Grow>
      <Grow in={toSubmit?.length > 0}
          {...(toSubmit?.length > 0 ? { timeout: 1000 } : {})}
      >
        <Grid lg={12} sm={12} sx={{mt:5}} display="flex" flexDirection="row" justifyContent="end">
          <Button variant='contained' onClick={hanldeSubmit} size='small' color='success' startIcon={<FontAwesomeIcon icon={faCheckCircle}/>}>Submit Quotations</Button>
          <ShowPackageItems key={2} data={listData} open={viewList} handleClose={closeList}/>
        </Grid>
      </Grow>
    </Grid>
  </Grid>
  )
}
