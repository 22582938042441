import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faSearch } from '@fortawesome/fontawesome-free-solid';
import { NumberFormating } from './formatting';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import { useNavigate } from 'react-router-dom';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export default function ItemTable({data, handleDeleteRaw}) {

  // const downloadFile = (file, type) => {
  //   var data = new Blob([file], {type: 'application/pdf'});
  //   var csvURL = window.URL.createObjectURL(data);
  //   var tempLink = document.createElement('a');
  //   tempLink.href = csvURL;
  //   if(type == 1){
  //     tempLink.setAttribute('download', 'quotation.pdf');
  //   }else{
  //     tempLink.setAttribute('download', 'specification.pdf');
  //   }
  //   tempLink.click();
  // }

  const downloadFile = (file1, file2) => {
    const zip = new JSZip();
    const pdfBlob1 = new Blob([file1], { type: 'application/pdf' });
    const pdfBlob2 = new Blob([file2], { type: 'application/pdf' });
    zip.file("quotation.pdf", pdfBlob1);
    zip.file("specification.pdf", pdfBlob2);
    zip.generateAsync({ type: "blob" })
      .then((content) => {
        saveAs(content, "documents.zip");
      });
  }

  return (
    <TableContainer component={Paper} sx={{boxShadow:5}}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell sx={{fontSize:'0.70rem'}} align="center">Quo/Spec</TableCell>
            <TableCell sx={{fontSize:'0.75rem'}} align="right">Currency Type</TableCell>
            <TableCell sx={{fontSize:'0.75rem'}} align="right">Unit Price</TableCell>
            <TableCell sx={{fontSize:'0.75rem'}} align="right">Unit Price (+Tax)</TableCell>
            <TableCell sx={{fontSize:'0.75rem'}} align="right">Total Price (+Tax)</TableCell>
            <TableCell sx={{fontSize:'0.80rem'}} align="center">Lead Time (Days)</TableCell>
            <TableCell sx={{fontSize:'0.75rem'}}>Remark</TableCell>
            <TableCell sx={{fontSize:'0.75rem'}} align="center">Opt</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data?.length > 0 && data.map((value, index) => {
              return(
                
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell  align="center">
                      <IconButton target="_blank" onClick={() => downloadFile(value.quotation, value.specification)} size='small' title='Dowload Quotation/Spec'>
                          <FolderZipIcon sx={{fontSize:'1.3rem'}}/>
                      </IconButton>
                  </TableCell>
                  <TableCell  sx={{fontSize:'0.75rem'}} align="center">{value?.currency_type?.code}</TableCell>
                  <TableCell  sx={{fontSize:'0.75rem'}} align="right">{NumberFormating(value.exclusive_price, 2)}</TableCell>
                  <TableCell  sx={{fontSize:'0.75rem'}} align="right">{NumberFormating(value.price, 2)}</TableCell>
                  <TableCell  sx={{fontSize:'0.75rem'}} align="right">{NumberFormating(value.total_price,2)}</TableCell>
                  <TableCell  sx={{fontSize:'0.75rem'}} align="right">{value?.lead_time}</TableCell>
                  <TableCell  sx={{fontSize:'0.75rem'}}>{value.remark}</TableCell>
                  <TableCell  sx={{fontSize:'0.75rem'}} align="center">
                      <IconButton onClick={() => handleDeleteRaw(index)} color='error' size='small'>
                          <DeleteIcon  sx={{fontSize:'1.3rem'}}/>
                      </IconButton>
                  </TableCell>
                </TableRow>
              )
            })
          }

        </TableBody>
      </Table>
    </TableContainer>
  );
}