
import { Button, Card, Container, Divider, Grid, Grow, TextField } from '@mui/material'
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPlusCircle } from '@fortawesome/fontawesome-free-solid';
import ItemTable from '../components/table';
import { ConfirmationAjaxMsg, ErrorMsg, SuccessMsg } from '../components/alert';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import LoadingOverlay from 'react-loading-overlay';
import { PulseLoader } from 'react-spinners';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { PROXY_SERVER } from '../Config';
import Moment from 'react-moment';
import { NumberFormatInput } from '../components/formatting';
import Create from '../components/Form/create';
import ReworkCreate from '../components/Rework/create';
import Submitted from '../components/Form/submitted';
import Expired from '../components/expired';



function appBarLabel(label) {
  return (
    <Toolbar>
      <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
        {label}
      </Typography>
    </Toolbar>
  );
}

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1976d2',
    },
  },
});

export default function Index() {
  const [loading, setLoading] = React.useState(true);
  const [dataSet, setDataSet] = React.useState([]);
  const [toSubmit, setToSubmit] = React.useState([]);
  const [formStatus, setFormStatus] = React.useState(0);
  const [pageStatus, setPageStatus] = React.useState(1);
  const [previewTable, setPreviewTable] = React.useState([]);
  const [manualRender, setManualRender] = React.useState(false);
  const location = useLocation();

  const hanldeSubmit =() => {
    let pathname = location.pathname;
    let slug = pathname.split("/")[pathname.split("/").length-1];
    let formData = new FormData();
    // formData.append('data', JSON.stringify(toSubmit));
    
    let arr = toSubmit;
    toSubmit.length > 0 && toSubmit.map((value, index) => {
      if(value.quotation instanceof File){
        formData.append('file_quotation_'+index, value.quotation);
        arr[index].quotation = "file_quotation_"+index;
      }
      if(value.specification instanceof File){
        formData.append('file_spec_'+index, value.specification);
        arr[index].specification = "file_spec_"+index;
      }
    })
    formData.append('data', JSON.stringify(arr));
    ConfirmationAjaxMsg(
      "Do you want to submit quotations",
      "info",
      "none",
      `/portal/quotation/${slug}`,
      formData,
      "Yes, Submit it!",
    ).then((value) =>{
      if(value.value.status == 201){
        SuccessMsg("Quotation Submitted Successfully")
        .then((value) => {
          loadOpenData();
        })
      }else{
        ErrorMsg("Something Went Wrong!");
      }
    })
  }

  //yup validation rules
  const validationSchema = Yup.object({
    quotation:Yup.string().required('Quotation is required')
    .test('fileSize', 'File size is too large. Max size is 2MB', value => {
      if (formik.values.quotation == '') {
        return true;
      }
      const maxSizeInBytes = 2.1 * 1024 * 1024; 
      return formik?.values?.quotation?.size <= maxSizeInBytes;
    }).test('fileType', 'Only accept pdf file format', value => {
      if(formik?.values?.quotation?.type == 'application/pdf'){
        return true
      }else{
        return false;
      }
    }),
    specification:Yup.string().required('Specification is required')
    .test('fileSize', 'File size is too large. Max size is 2MB', value => {
      if (formik.values.specification == '') {
        return true;
      }
      const maxSizeInBytes = 2.1 * 1024 * 1024; 
      return formik?.values?.specification?.size <= maxSizeInBytes;
    }).test('fileType', 'Only accept pdf file format', value => {
      if(formik?.values?.specification?.type == 'application/pdf'){
        return true
      }else{
        return false;
      }
    }),
    exclusive_price: Yup.number().required('Unit Price is required'),
    price:Yup.number().required('Unit Price (+Tax) is required'),
    total_price: Yup.number().required('Total Price (+Tax) is required'),
    currency_type:Yup.object().nullable().required('Currency Type is required'),
    lead_time:Yup.number().nullable().required('Lead Time is required'),
  });

  //yup validation rules
  const validationSchemaRework = Yup.object({
    quotation:Yup.string().required('Quotation is required')
    .test('fileSize', 'File size is too large. Max size is 2MB', value => {
      if (formik2.values.quotation == '') {
        return true;
      }
      const maxSizeInBytes = 2.1 * 1024 * 1024; 
      return formik2?.values?.quotation?.size <= maxSizeInBytes;
    }).test('fileType', 'Only accept pdf file format', value => {
      if(formik2?.values?.quotation?.type == 'application/pdf'){
        return true
      }else{
        return false;
      }
    }),
    specification:Yup.string().required('Specification is required')
    .test('fileSize', 'File size is too large. Max size is 2MB', value => {
      console.log('formik2.values.specification', formik2.values.specification)
      if (formik2.values.specification == '') {
        return true;
      }
      const maxSizeInBytes = 2.1 * 1024 * 1024; 
      return formik2?.values?.specification?.size <= maxSizeInBytes;
    }).test('fileType', 'Only accept pdf file format', value => {
      if(formik2?.values?.specification?.type == 'application/pdf'){
        return true
      }else{
        return false;
      }
    }),
    exclusive_price: Yup.number().required('Unit Price is required'),
    price:Yup.number().required('Unit Price (+Tax) is required'),
    total_price: Yup.number().required('Total Price (+Tax) is required'),
    currency_type:Yup.object().nullable().required('Currency Type is required'),
    lead_time:Yup.number().nullable().required('Lead Time is required'),
  });

   //formik form handling
   const formik = useFormik({
    initialValues: {
        quotation:'',
        specification:'',
        price:'',
        total_price:'',
        remark:'',
        exclusive_price:'',
        currency_type:null,
        lead_time:'',
    },
    validateOnBlur:false,
    validateOnChange:false,
    validationSchema:validationSchema,
    onSubmit: async(values) => {
        try{
            setLoading(true);
            let submit = toSubmit;
            submit.push(values);
            setToSubmit(submit);
            formik.handleReset();
            document.getElementById("quotation").value = "";
            document.getElementById("specification").value = "";
        }catch(error){
            console.log('error', error)
        }
        setLoading(false);
    },
});

 //formik form handling
 const formik2 = useFormik({
  initialValues: {
      quotation:'',
      specification:'',
      price:'',
      total_price:'',
      remark:'',
      exclusive_price:'',
      currency_type:null,
      lead_time:'',
  },
  validateOnBlur:false,
  validateOnChange:false,
  validationSchema:validationSchemaRework,
  onSubmit: async(values) => {
      try{
          setLoading(true);
          let submit = toSubmit;
          submit.push(values);
          setToSubmit(submit);
          setManualRender(!manualRender);
          hanldeSubmit();
      }catch(error){
          console.log('error', error)
      }
      setLoading(false);
  },
});

const handleReworkSubmit = () => {
  formik2.handleSubmit();
}

React.useEffect(() => {
  loadOpenData();
}, [])

const loadOpenData = () => {
  let pathname = location.pathname;
  let slug = pathname.split("/")[pathname.split("/").length-1];

  axios.get(PROXY_SERVER+`/portal/requisition/${slug}`)
  .then((value) => {
    if(value.status == 200){
      let active_currencies = [];
      value?.data?.currenceis.length > 0 && value?.data?.currenceis.map((cr) => {
        if(cr.status == 1){
          active_currencies.push(cr);
        }
      })
      value.data.active_currencies = active_currencies;
      setDataSet(value.data);
      if(value?.data?.quotation_request?.is_reworked == 1){
        setPageStatus(2);
      }
      if(value?.data?.is_expired == true){
        setFormStatus(3);
      }else{
        if(value?.data?.quotation_request?.status == 0){
          setFormStatus(1)
        }else if(value?.data?.quotation_request?.status == 1){
          setFormStatus(2)
          setPreviewTable(value?.data?.quotations);
        }else{
          setFormStatus(3)
        }
      }
    }else{
      ErrorMsg("Something Went Wrong!");
    }
    setLoading(false);
  })
}

const handleDeleteRaw = (index) => {
  let data = toSubmit;
  data.splice(index, 1);
  setToSubmit([...data]);
}

  return (
    <LoadingOverlay
      active={loading}
      spinner={<PulseLoader color='#4da964'/>}
      style={{background:'#4da964'}}
    >
    <Container maxWidth="lg" style={{ height:'100vh'}}>
      <Stack spacing={2} sx={{ flexGrow: 0 }}>
        <ThemeProvider theme={darkTheme}>
          <AppBar position="static" color="primary">
            {
              dataSet?.quotation_request?.suppliers?.[0].name != undefined?
              appBarLabel(`Quotation Request | ${dataSet?.quotation_request?.suppliers?.[0].name}`)
              :null
            }
           
          </AppBar>
        </ThemeProvider>
      </Stack>
      <Card sx={{flexGrow: 1, minHeight:'80vh' }} variant="outlined">
        <form onSubmit={formik.handleSubmit}>
          {
          pageStatus == 1?
            formStatus == 1?
              <Create formik={formik} handleDeleteRaw={handleDeleteRaw} dataSet={dataSet} toSubmit={toSubmit} hanldeSubmit={hanldeSubmit}/>
            :(
              formStatus == 2?
              <Submitted data={previewTable} dataSet={dataSet} />
              :
              (formStatus == 3?
                <Expired/>
                :null
              )
            )
          :
             formStatus == 3?
             <Expired/>
             :
              (
                formStatus == 1?
                  <ReworkCreate formik={formik2} handleDeleteRaw={handleDeleteRaw} dataSet={dataSet} toSubmit={toSubmit} hanldeSubmit={handleReworkSubmit}/>
                :
                formStatus == 2?
                  <Submitted data={previewTable}  dataSet={dataSet}/>
                : (formStatus == 3?
                  <Expired/>
                  :null
                )
              )
          }
            
        </form>
      </Card>
    </Container>
    </LoadingOverlay>
  )
}
