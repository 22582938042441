import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages';

function App() {
  return (
    <div className="app">
        <Routes> 
          <Route path="/" element={<Home/>} />
        </Routes>
    </div>
  );
}

export default App;
