import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

export default function ShowPackageItems({open, handleClose, data}) {
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

  return (
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
      >
        <DialogTitle sx={{fontSize:18}} id="alert-dialog-title">
          {"Show Package Items"}
        </DialogTitle>
        <DialogContent>
            <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>No.</strong></TableCell>
                            <TableCell><strong>Item</strong></TableCell>
                            <TableCell align='center'><strong>Quantity</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data && data.length > 0 && data.map((val, index) => {
                                return(
                                <StyledTableRow key={index}>
                                    <TableCell style={{fontSize:12, paddingTop:8, paddingBottom:8}} component="th" scope="row">
                                        {index+1}
                                    </TableCell>
                                    <TableCell style={{fontSize:12, paddingTop:8, paddingBottom:8}} component="th" scope="row">
                                        {val?.asset_item?.[0]?.name}
                                    </TableCell>
                                    <TableCell style={{fontSize:12, paddingTop:8, paddingBottom:8}} align='center'>{val.quantity}</TableCell>
                                </StyledTableRow>
                                )
                            })
                        }
                    </TableBody>
            </Table>
        </DialogContent>
        <DialogActions>
          <Button color='error' variant='contained' onClick={() => handleClose(false)} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
  );
}
