import { NumericFormat, PatternFormat  } from 'react-number-format';

export function NumberFormating(number,fraction){
    return Number(number).toLocaleString(navigator.language, { minimumFractionDigits: fraction });
}

export function NumberFormatInput(props) {
  
    const { inputRef, onChange, ...other } = props;
    return (
        <NumericFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
            onChange({
            target: {
                name: props.name,
                value: values.value
            }
            });
        }}
        thousandSeparator
        // isNumericString
        />
    );
}