
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import axios from 'axios';
import { PROXY_SERVER, PROXY_SERVER2 } from '../Config';

export function ConfirmationAjaxMsg(msg, type, input="none", url, data, confirmButtonText="Yes, Approve it!", defaultValue=null, confirmButtonColor="#388e3c"){
    return Swal.fire({
      icon:type,
      title: 'Are you sure?',
      text:msg,
      input: input,
      inputValue: 3,
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      confirmButtonColor:confirmButtonColor,
      cancelButtonColor:'#f83245',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      preConfirm: async(text) => {
        if(input != "" && input != "none"){
          data.append('input', text);
        }
          let response = await axios.post(PROXY_SERVER+url, data);
          (response.status == 500 || response.status == 401) && Swal.showValidationMessage(
                `Request failed: Something went wrong!`
              )
          return response;
      },
      allowOutsideClick: () => !Swal.isLoading()
    })
  }

  export function SuccessMsg(msg, title="Success!"){

    return Swal.fire({
      icon:'success',
      title:title,
      html:msg,
      customClass: {
        container: 'msgbox-zindex',
        title: 'text-success',
      },
      returnInputValueOnDeny: true
    })
  }

  export function ErrorMsg(msg){
    return Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: msg,
        customClass: {
          container: 'msgbox-zindex',
          icon: 'text-danger',
          title: 'text-danger',
        }
      })
  }