import { faExclamationTriangle } from '@fortawesome/fontawesome-free-solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Typography } from '@mui/material'
import React from 'react'

export default function Expired() {
  return (
    <Grid container lg={12} sm={12} sx={{display:'flex', flexDirection:'row'}}>
    <Grid item lg={12} sm={12} sx={{padding:5}} display="flex" flexDirection="row" justifyContent="center">
        <FontAwesomeIcon style={{fontSize:100, color:'#a0b0a2b3'}} icon={faExclamationTriangle} />
    </Grid>
    <Grid item lg={12} sm={12} sx={{padding:5}} display="flex" flexDirection="row" justifyContent="center">
         <Typography sx={{fontSize:18, fontWeight:600}} textAlign="center">Sorry, The Link has Expired.</Typography>
    </Grid>
  </Grid>
  )
}
